// Imports
          import { Link } from 'react-router-dom'
import React, { useEffect, useMemo,useContext } from 'react';

import { useState } from 'react';

import '../../../../App.css';
import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component8Page() {
const navigate = useNavigate()


const [content,setContent]= useState({})   

const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const bloc1_1_4_onClick = async (e) => {
                try{ 
                      navigate('/#abonnement');
                }
                catch(e){
                  console.log("Error bloc1_1_4_onClick ")
                }  
              };

const bloc6_3_onClick = async (e) => {
                try{ 
                      var obj = document.querySelector('#abonnement');
              if (obj) {
                console.log("scroll by Onclick  ", obj.id, obj);
                obj.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                  inline: "center",
                });

              }
                }
                catch(e){
                  console.log("Error bloc6_3_onClick ")
                }  
              };

const bloc6_3_0_onClick = async (e) => {
                try{ 
                      var obj = document.querySelector('#abonnement');
              if (obj) {
                console.log("scroll by Onclick  ", obj.id, obj);
                obj.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                  inline: "center",
                });

              }
                }
                catch(e){
                  console.log("Error bloc6_3_0_onClick ")
                }  
              };

const action_447 =   async ({TemplateConsent,setTemplateConsent})=> {
        try { 
        window.alert("TEST OK")
}
        catch (error) {
        console.error(error);
        }
      };  

const action_event_447_bloc9 =  async(event)=>{

                            
                           
              
                          await action_447( { event }) 
              
                          };  

const action_453 =   async ({Etat,setEtat})=> {
        try { 
        setEtat(!Etat)
}
        catch (error) {
        console.error(error);
        }
      };  

const setEtatbloc9_1_1 =(value)=> setInternal("etat1",value);

const action_event_453_bloc9_1_1 =  async(event)=>{

                            
                           var Etat = content["etat1"];
              
                          await action_453( { Etat,event, setEtat:setEtatbloc9_1_1  }) 
              
                          };  

const action_454 =   async ({Etat,setEtat})=> {
        try { 
        setEtat(Etat!=="vert" ? "vert":"rose")
}
        catch (error) {
        console.error(error);
        }
      };  

const setEtatbloc9_1_2 =(value)=> setInternal("etat2",value);

const action_event_454_bloc9_1_2 =  async(event)=>{

                            
                           var Etat = content["etat2"];
              
                          await action_454( { Etat,event, setEtat:setEtatbloc9_1_2  }) 
              
                          };  

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-full flex flex-col"}     > <div id='bloc0_0'  className={ "w-full   p-4 text-center bg-secondary_color text-white text-lg letter-spacing=0.2rem"}   style = { {"letter-spacing":"0.2rem"}}   >{`100% BIOLOGIQUE . 100% LOCAL . 100% DE SAISON . LIVRAISON GRATUITE .`} </div>
          <div id='bloc0_1'  className={ "w-full flex flex-row justify-between items-center text-black  z-10 backgroundColor=#F8F8F8 pl-4"}   style = { {"backgroundColor":"#F8F8F8"}}   > <img  id='bloc0_1_0' src="https://fs.appisyou.com/apps/konjaque/Logo Konjaquea1709905421504.png" className={ "w-10 h-10 rounded-xl m-2    object-cover"}     alt="undefined" />
            <div id='bloc0_1_1'  className={ " text-base grow text-left uppercase   letter-spacing=0.3rem font-size=25px"}   style = { {"letter-spacing":"0.3rem","font-size":"25px"}}   >{`Konjaque`} </div>
            <div id='bloc0_1_2'  className={ "w-20  relative h-12"}     > <FontAwesomeIcon  id='bloc0_1_2_0'  icon={ fa.faArrowRightToBracket}      className={ "p-4 absolute"}  />
              <FontAwesomeIcon  id='bloc0_1_2_1'  icon={ fa.faHomeLgAlt}      className={ "p-4 text-white absolute"}  /></div></div></div><div id='bloc1'  className={ "w-full py-4 grid  md:grid-cols-2   grid-cols-1 gap-4 backgroundColor=#F8F8F8 "}   style = { {"backgroundColor":"#F8F8F8"}}   > <div id='bloc1_0'  className={ "flex flex-row w-full justify-center md:justify-end  mt-10"}     > <img  id='bloc1_0_0' src="https://fs.appisyou.com/apps/konjaque/panier-removebg-preview1708331093664.png" className={ " w-full  md:w-3/4  h-50    object-cover"}     alt="undefined" /></div>
          <div id='bloc1_1'  className={ "flex flex-col p-4 py-10 justify-center  items-center  md:justify-center md:items-start "}     > <div id='bloc1_1_0'  className={ "font-size=3rem  py-2"}   style = { {"font-size":"3rem"}}   >{` RECEVEZ VOTRE PANIER`} </div>
            <div id='bloc1_1_1'  className={ " flex md:flex-row flex-col justify-center items-center py-4"}     > <div id='bloc1_1_1_0'  className={ "font-size=6rem line-height=6rem"}   style = { {"font-size":"6rem","line-height":"6rem"}}   >{`100%`} </div>
              <div id='bloc1_1_1_1'  className={ "flex md:flex-col text-xs justify-start items-start px-3 font-size=1.5rem line-height=1.5rem"}   style = { {"font-size":"1.5rem","line-height":"1.5rem"}}   > <div id='bloc1_1_1_1_0'       >{`BIO`} </div>
                <div id='bloc1_1_1_1_1'       >{`LOCAL`} </div>
                <div id='bloc1_1_1_1_2'       >{`DE SAISON`} </div></div></div>
            <div id='bloc1_1_2'       > </div>
            <div id='bloc1_1_3'  className={ "font-size=3rem    pt-2 pb-6"}   style = { {"font-size":"3rem"}}   >{`CHAQUE SEMAINE`} </div>
            <Link to="/#abonnement"><div id='bloc1_1_4'  className={ "shadow bg-orange-400 bg-primary_color p-2 flex flex-row text-white justify-center items-center font-size=2rem px-6 letter-spacing=0.4vw bottom=-1.5rem"}   style = { {"font-size":"2rem","letter-spacing":"0.4vw","bottom":"-1.5rem"}}  onClick = { bloc1_1_4_onClick } > <div id='bloc1_1_4_0'       >{`S'ABONNER`} </div>
              <FontAwesomeIcon  id='bloc1_1_4_1'  icon={ fa.faArrowRight}      className={ "p-1"}  /></div></Link></div></div><div id='bloc2'  className={ "w-full p-4 flex  md:flex-row  flex-col-reverse gap-4"}     > <div id='bloc2_0'  className={ "p-2 flex flex-col justify-center items-center"}     > <div id='bloc2_0_0'  className={ "p-2 flex flex-col justify-start items-start w-full"}     > <div id='bloc2_0_0_0'  className={ "text-2xl font-bold"}     >{`Un abonnement simple et sain.`} </div>
              <div id='bloc2_0_0_1'  className={ "font-bold"}     >{`Nous n’avons pas encore trouvé mieux pour notre santé que de manger bio, local et de saison.`} </div>
              <div id='bloc2_0_0_2'       >{`Alors nous vous livrons chaque semaine au créneau habituel choisi des paniers frais de légumes, fruits et aromates avec une répartition équilibrée et variée pour vous apporter le meilleur de la nature à côté de chez vous en vitamines et minéraux et en goût ! En cas d'absence, vous gérez votre abonnement comme bon vous semble, tout simplement.`} </div></div>
            <div id='bloc2_0_1'  className={ "p-2 flex flex-col justify-start items-start w-full"}     > <div id='bloc2_0_1_0'  className={ "text-2xl font-bold"}     >{`Pour nous. Pour la biodiversité. Pour les générations à venir.`} </div>
              <div id='bloc2_0_1_1'  className={ "font-bold"}     >{`Et nous n’avons pas trouvé mieux non plus pour notre environnement !`} </div>
              <div id='bloc2_0_1_2'       >{`Consommer, c’est interagir avec notre environnement, entre nous individus mais aussi avec les ressources naturelles de notre planète d’aujourd’hui et de demain que nous devons également entretenir.  Chez Konjaque aussi, consommer c’est politique !`} </div></div>
            <div id='bloc2_0_2'  className={ "text-center text-white bg-primary_color p-2 shadow"}     > <div id='bloc2_0_2_0'  className={ "text-right w-full"}     >{`en savoir plus sur notre mission ->`} </div></div></div>
          <div id='bloc2_1'  className={ "flex items-center justifiy-center  w-full"}     > <img  id='bloc2_1_0' src="https://fs.appisyou.com/apps/konjaque/image11708270416942.png" className={ "h-full  w-full bg-secondary_colr    object-cover"}     alt="undefined" /></div></div><div id='bloc3'  className={ "w-full  flex flex-col md:flex-row items-center justify-center"}     > <div id='bloc3_0'  className={ "flex flex-col text-white text-xl justify-center items-center mx-2 mb-12  w-full  bg-secondary_color font-bold p-4"}     > <div id='bloc3_0_0'  className={ "p-4 font-bold  border-white text-2xl w-full  bg-secondary_color  h-32 flex justify-center items-cente"}     >{`Classique.`} </div>
            <img  id='bloc3_0_1' src="https://fs.appisyou.com/apps/konjaque/image_B-removebg-preview1708270406058.png,https://fs.appisyou.com/apps/konjaque/pack-panier-famille1709135046311.jpg" className={ "h-80 w-full bg-white   object-cover"}     alt="undefined" />
            <div id='bloc3_0_2'  className={ "p-2 flex flex-col  text-base "}     > <div id='bloc3_0_2_0'  className={ "text-xl font-bold"}     >{`3kg / semaine`} </div>
              <div id='bloc3_0_2_1'  className={ "p-2 text-xl font-bold  mb-2 flex flex-row"}     > <div id='bloc3_0_2_1_0'       >{`79.90`} </div>
                <div id='bloc3_0_2_1_1'       >{`€ / mois`} </div></div></div>
            <div id='bloc3_0_3'  className={ " p-2  bg-white border-primary_color border-2  text-primary_color -mb-6 shadow text-base flex flex-row centre  items-center justify-center"}     > <div id='bloc3_0_3_0'       >{`s'abonner`} </div>
              <FontAwesomeIcon  id='bloc3_0_3_1'  icon={ fa.faArrowRightLong}      className={ "p-1"}  /></div></div>
          <div id='bloc3_1'  className={ "flex flex-col text-white text-xl justify-center items-center mx-2 mb-12  w-full  bg-secondary_color font-bold p-4"}     > <div id='bloc3_1_0'  className={ "p-4 font-bold  border-white text-2xl w-full  bg-secondary_color  h-32 flex justify-center items-cente"}     >{`Familial.`} </div>
            <img  id='bloc3_1_1' src="https://fs.appisyou.com/apps/konjaque/panier-removebg-preview1708331093664.png" className={ "h-80 w-full bg-white   object-cover"}     alt="undefined" />
            <div id='bloc3_1_2'  className={ "p-2 flex flex-col  text-base "}     > <div id='bloc3_1_2_0'  className={ "text-xl font-bold"}     >{`6kg / semaine`} </div>
              <div id='bloc3_1_2_1'  className={ "p-2 text-xl font-bold  mb-2 flex flex-row"}     > <div id='bloc3_1_2_1_0'       >{`139.90`} </div>
                <div id='bloc3_1_2_1_1'       >{`€ / mois`} </div></div></div>
            <div id='bloc3_1_3'  className={ " p-2  bg-white border-primary_color border-2  text-primary_color -mb-6 shadow text-base flex flex-row centre  items-center justify-center"}     > <div id='bloc3_1_3_0'       >{`s'abonner`} </div>
              <FontAwesomeIcon  id='bloc3_1_3_1'  icon={ fa.faArrowRightLong}      className={ "p-1"}  /></div></div></div><div id='bloc4'  className={ "w-ful flex justify-center text-base flex-col items-center py-4"}     > <div id='bloc4_0'  className={ "  md:w-full grid md:grid-cols-4 grid-cols-1 gap-2 xl:w-full justify-center items-start w-3/4"}     > <div id='bloc4_0_0'  className={ "flex flex-col justify-start items-center p-4"}     > <img  id='bloc4_0_0_0' src="https://fs.appisyou.com/apps/konjaque/Logo Konjaque fond transparenta1709910522952.png" className={ "h-10 w-10 m-1   object-cover"}     alt="undefined" />
              <div id='bloc4_0_0_1'  className={ "font-bold"}     >{`Bio. local. de saison`} </div>
              <div id='bloc4_0_0_2'       >{`Offrez vous au quotidien la meilleure  base alimentaire possible :  un panier frais et varié de légumes, fruits et  aromates 100 % bio, local et de saison.`} </div></div>
            <div id='bloc4_0_1'  className={ "flex flex-col justify-start items-center p-4"}     > <img  id='bloc4_0_1_0' src="https://fs.appisyou.com/apps/konjaque/Logo Konjaque fond transparenta1709910522952.png" className={ "h-10 w-10 m-1   object-cover"}     alt="undefined" />
              <div id='bloc4_0_1_1'  className={ "font-bold"}     >{`Livré à la maison`} </div>
              <div id='bloc4_0_1_2'       >{`Chaque semaine directement au domicile  au créneau horaire habituel choisi pour encore  plus de fraîcheur et de simplicité.`} </div></div>
            <div id='bloc4_0_2'  className={ "flex flex-col justify-start items-center p-4"}     > <img  id='bloc4_0_2_0' src="https://fs.appisyou.com/apps/konjaque/Logo Konjaque fond transparenta1709910522952.png" className={ "h-10 w-10 m-1   object-cover"}     alt="undefined" />
              <div id='bloc4_0_2_1'  className={ "font-bold"}     >{`Libre à l’infini`} </div>
              <div id='bloc4_0_2_2'       >{`Sentez vous libre de stopper, reprendre et arrêter  votre abonnement à chaque instant.`} </div></div>
            <div id='bloc4_0_3'  className={ "flex flex-col justify-start items-center p-4"}     > <img  id='bloc4_0_3_0' src="https://fs.appisyou.com/apps/konjaque/Logo Konjaque fond transparenta1709910522952.png" className={ "h-10 w-10 m-1   object-cover"}     alt="undefined" />
              <div id='bloc4_0_3_1'  className={ "font-bold"}     >{`De la valeur dans l'assiette`} </div>
              <div id='bloc4_0_3_2'       >{`Abonné Konjaque, vous participez à notre projet  de contribuer à un monde meilleur.`} </div></div></div>
          <div id='bloc4_1'  className={ "text-right text-white bg-primary_color p-2 shadow text-sm"}     > <div id='bloc4_1_0'  className={ "text-right w-full"}     >{`en savoir plus sur nos valeurs ->`} </div></div></div><div id='bloc5'  className={ "w-full flex justify-center text-base flex-col items-center py-4"}     > <div id='bloc5_0'  className={ "  md:w-full grid md:grid-cols-3 grid-cols-1 gap-2 xl:w-3/4 justify-center items-start w-2/3"}     > <div id='bloc5_0_0'  className={ "flex flex-col items-center justify-start"}     > <img  id='bloc5_0_0_0' src="https://fs.appisyou.com/apps/konjaque/AB_cut_copie1708331423681.png" className={ "h-40 p-2   object-cover"}     alt="undefined" />
              <div id='bloc5_0_0_1'  className={ "text-secondary_color font-bold"}     >{`Agriculture biologique`} </div></div>
            <div id='bloc5_0_1'  className={ "flex flex-col items-center justify-start"}     > <img  id='bloc5_0_1_0' src="https://fs.appisyou.com/apps/konjaque/drapeau1708334966129.jpg" className={ "h-40 p-2   object-cover"}     alt="undefined" />
              <div id='bloc5_0_1_1'  className={ "text-secondary_color font-bold"}     >{`Produit en France`} </div></div>
            <div id='bloc5_0_2'  className={ "flex flex-col items-center justify-start"}     > <img  id='bloc5_0_2_0' src="https://fs.appisyou.com/apps/konjaque/uebio1708331414768.png" className={ "h-40 p-2   object-cover"}     alt="undefined" />
              <div id='bloc5_0_2_1'  className={ "text-secondary_color font-bold"}     >{`Label européen`} </div></div></div></div><div id='bloc6'  className={ "w-full flex flex-col items-center justify-center py-4"}     > <div id='bloc6_0'  className={ "text-2xl font-bold text-primary_color uppercase p-2"}     >{`l'abonnement du panier Konjaque,`} </div>
          <div id='bloc6_1'  className={ "text-2xl font-bold text-primary_color italic"}     >{`Comment ça marche ?`} </div>
          <div id='bloc6_2'  className={ "  md:w-full grid md:grid-cols-4 grid-cols-1 gap-2 xl:w-3/4 justify-center items-start w-2/3"}     > <div id='bloc6_2_0'  className={ "flex flex-col justify-start items-center p-2"}     > <div id='bloc6_2_0_0'  className={ "flex items-center justify-center"}     > <div id='bloc6_2_0_0_0'  className={ "font-bold text-2xl text-secondary_color opacity-0"}     >{`----`} </div>
                <div id='bloc6_2_0_0_1'  className={ "font-bold text-xs  rounded-full w-8 h-8 flex items-center justify-center border-4  border-primary_color text-primary_color m-2"}     >{`1`} </div>
                <div id='bloc6_2_0_0_2'  className={ "font-bold text-2xl text-primary_color md:opacity-100 "}     >{`----`} </div></div>
              <FontAwesomeIcon  id='bloc6_2_0_1'  icon={ fa.faMapMarkerAlt}      className={ "text-3xl m-1 text-primary_color"}  />
              <div id='bloc6_2_0_2'       >{`Indiquez votre lieu de livraison pour vérifier si vous êtes elligible`} </div>
              <FontAwesomeIcon  id='bloc6_2_0_3'  icon={ fa.faArrowDownLong}      className={ "text-3xl m-1 text-primary_color md:hidden"}  /></div>
            <div id='bloc6_2_1'  className={ "flex flex-col justify-start items-center p-2"}     > <div id='bloc6_2_1_0'  className={ "flex items-center justify-center"}     > <div id='bloc6_2_1_0_0'  className={ "font-bold text-2xl text-primary_color "}     >{`----`} </div>
                <div id='bloc6_2_1_0_1'  className={ "font-bold text-xs  rounded-full w-8 h-8 flex items-center justify-center border-4  border-primary_color text-primary_color m-2"}     >{`2`} </div>
                <div id='bloc6_2_1_0_2'  className={ "font-bold text-2xl text-primary_color "}     >{`----`} </div></div>
              <FontAwesomeIcon  id='bloc6_2_1_1'  icon={ fa.faBoxOpen}      className={ "text-3xl m-1 text-primary_color"}  />
              <div id='bloc6_2_1_2'       >{`Choisissez la taille de votre panier.`} </div>
              <FontAwesomeIcon  id='bloc6_2_1_3'  icon={ fa.faArrowDownLong}      className={ "text-3xl m-1 text-primary_color md:hidden"}  /></div>
            <div id='bloc6_2_2'  className={ "flex flex-col justify-start items-center p-2"}     > <div id='bloc6_2_2_0'  className={ "flex items-center justify-center"}     > <div id='bloc6_2_2_0_0'  className={ "font-bold text-2xl text-primary_color "}     >{`----`} </div>
                <div id='bloc6_2_2_0_1'  className={ "font-bold text-xs  rounded-full w-8 h-8 flex items-center justify-center border-4  border-primary_color text-primary_color m-2"}     >{`3`} </div>
                <div id='bloc6_2_2_0_2'  className={ "font-bold text-2xl text-primary_color "}     >{`----`} </div></div>
              <FontAwesomeIcon  id='bloc6_2_2_1'  icon={ fa.faTruck}      className={ "text-3xl m-1 text-primary_color"}  />
              <div id='bloc6_2_2_2'       >{`Recevez chaque semaine à domicile votre panier  au créneau horaire choisi`} </div>
              <FontAwesomeIcon  id='bloc6_2_2_3'  icon={ fa.faArrowDownLong}      className={ "text-3xl m-1 text-primary_color md:hidden"}  /></div>
            <div id='bloc6_2_3'  className={ "flex flex-col justify-start items-center p-2"}     > <div id='bloc6_2_3_0'  className={ "flex items-center justify-center"}     > <div id='bloc6_2_3_0_0'  className={ "font-bold text-2xl text-primary_color "}     >{`----`} </div>
                <div id='bloc6_2_3_0_1'  className={ "font-bold text-xs  rounded-full w-8 h-8 flex items-center justify-center border-4  border-primary_color text-primary_color m-2"}     >{`4`} </div>
                <div id='bloc6_2_3_0_2'  className={ "font-bold text-2xl text-secondary_color opacity-0"}     >{`----`} </div></div>
              <FontAwesomeIcon  id='bloc6_2_3_1'  icon={ fa.faShareNodes}      className={ "text-3xl m-1 text-primary_color"}  />
              <div id='bloc6_2_3_2'       >{`Poursuivez, stoppez et reprenez votre abonnement comme bon vous semble.`} </div></div></div>
          <Link to="#abonnement"><div id='bloc6_3'  className={ "shadow bg-orange-400 bg-primary_color p-2 flex flex-row text-white justify-center items-center "}    onClick = { bloc6_3_onClick } > <Link to="#abonnement"><div id='bloc6_3_0'      onClick = { bloc6_3_0_onClick } >{`S'ABONNER`} </div></Link>
            <FontAwesomeIcon  id='bloc6_3_1'  icon={ fa.faArrowRight}      className={ "p-1"}  /></div></Link></div><div id='bloc7'  className={ "w-screen flex flex-col items-start my-10 overflow-hidden"}     > <div id='bloc7_0'  className={ "font-bold text-xl  p-2"}     >{`Les meilleurs avis`} </div>
          <div id='bloc7_1'  className={ "w-screen overflow-x-scroll "}     > <div id='bloc7_1_0'  className={ "flex flex-row"}     > <div id='bloc7_1_0_0x0'  className={ "w-64 p-1  w-2 h-2"}     > <div id='bloc7_1_0_0x0_0'  className={ "w-full h-40 overflow-hidden shadow flex flex-col border border-zinc-300 rounded-xl  p-2 justify-between items-start text-sm"}     > <div id='bloc7_1_0_0x0_0_0'  className={ "flex flex-col w-full items-start grow"}     > <div id='bloc7_1_0_0x0_0_0_0'  className={ "w-full flex flex-row items-center"}     > <div id='bloc7_1_0_0x0_0_0_0_0'  className={ "flex flex-row items-center"}     > <FontAwesomeIcon  id='bloc7_1_0_0x0_0_0_0_0_0'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc7_1_0_0x0_0_0_0_0_1'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc7_1_0_0x0_0_0_0_0_2'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc7_1_0_0x0_0_0_0_0_3'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc7_1_0_0x0_0_0_0_0_4'  icon={ fa.faStar}      className={ " w-2 h-2"}  /></div>
                      <div id='bloc7_1_0_0x0_0_0_0_1'  className={ "text-xs font-bold mx-2"}     >{`il y a 3 semaines`} </div></div>
                    <div id='bloc7_1_0_0x0_0_0_1'  className={ "p-1 text-left grow overflow-hidden h-12"}     >{`Excellent séjour, dans un cadre magnifique !  ???????? On a adoré !!!  Merci encore  ????`} </div>
                    <div id='bloc7_1_0_0x0_0_0_2'  className={ "underline text-xs font-bold p-1"}     >{`Afficher plus`} </div></div>
                  <div id='bloc7_1_0_0x0_0_1'  className={ "flex flex-row w-full"}     > <img  id='bloc7_1_0_0x0_0_1_0' src="https://fs.appisyou.com/apps/konjaque/41708938600437.jpg" className={ "h-10 w-10 rounded-full   object-cover"}     alt="undefined" />
                    <div id='bloc7_1_0_0x0_0_1_1'  className={ "flex flex-col items-start justify-center px-2"}     > <div id='bloc7_1_0_0x0_0_1_1_0'  className={ "font-bold text-sm"}     >{`James`} </div>
                      <div id='bloc7_1_0_0x0_0_1_1_1'  className={ "text-xs text-zinc-400"}     >{`Londres, Royaume-Uni`} </div></div></div></div></div>
      <div id='bloc7_1_0_0x1'  className={ "w-64 p-1  w-2 h-2"}     > <div id='bloc7_1_0_0x1_0'  className={ "w-full h-40 overflow-hidden shadow flex flex-col border border-zinc-300 rounded-xl  p-2 justify-between items-start text-sm"}     > <div id='bloc7_1_0_0x1_0_0'  className={ "flex flex-col w-full items-start grow"}     > <div id='bloc7_1_0_0x1_0_0_0'  className={ "w-full flex flex-row items-center"}     > <div id='bloc7_1_0_0x1_0_0_0_0'  className={ "flex flex-row items-center"}     > <FontAwesomeIcon  id='bloc7_1_0_0x1_0_0_0_0_0'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc7_1_0_0x1_0_0_0_0_1'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc7_1_0_0x1_0_0_0_0_2'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc7_1_0_0x1_0_0_0_0_3'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc7_1_0_0x1_0_0_0_0_4'  icon={ fa.faStar}      className={ " w-2 h-2"}  /></div>
                      <div id='bloc7_1_0_0x1_0_0_0_1'  className={ "text-xs font-bold mx-2"}     >{`il y a 1h`} </div></div>
                    <div id='bloc7_1_0_0x1_0_0_1'  className={ "p-1 text-left grow overflow-hidden h-12"}     >{`Au secours !!!! ???????? nous n'avons pas eu d'eau chaude durant tout le séjour service clientèle inexistant A fuir !!! ????????`} </div>
                    <div id='bloc7_1_0_0x1_0_0_2'  className={ "underline text-xs font-bold p-1"}     >{`Afficher plus`} </div></div>
                  <div id='bloc7_1_0_0x1_0_1'  className={ "flex flex-row w-full"}     > <img  id='bloc7_1_0_0x1_0_1_0' src="https://fs.appisyou.com/apps/konjaque/41708938600437.jpg" className={ "h-10 w-10 rounded-full   object-cover"}     alt="undefined" />
                    <div id='bloc7_1_0_0x1_0_1_1'  className={ "flex flex-col items-start justify-center px-2"}     > <div id='bloc7_1_0_0x1_0_1_1_0'  className={ "font-bold text-sm"}     >{`James`} </div>
                      <div id='bloc7_1_0_0x1_0_1_1_1'  className={ "text-xs text-zinc-400"}     >{`Londres, Royaume-Uni`} </div></div></div></div></div>
      <div id='bloc7_1_0_0x2'  className={ "w-64 p-1  w-2 h-2"}     > <div id='bloc7_1_0_0x2_0'  className={ "w-full h-40 overflow-hidden shadow flex flex-col border border-zinc-300 rounded-xl  p-2 justify-between items-start text-sm"}     > <div id='bloc7_1_0_0x2_0_0'  className={ "flex flex-col w-full items-start grow"}     > <div id='bloc7_1_0_0x2_0_0_0'  className={ "w-full flex flex-row items-center"}     > <div id='bloc7_1_0_0x2_0_0_0_0'  className={ "flex flex-row items-center"}     > <FontAwesomeIcon  id='bloc7_1_0_0x2_0_0_0_0_0'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc7_1_0_0x2_0_0_0_0_1'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc7_1_0_0x2_0_0_0_0_2'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc7_1_0_0x2_0_0_0_0_3'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc7_1_0_0x2_0_0_0_0_4'  icon={ fa.faStar}      className={ " w-2 h-2"}  /></div>
                      <div id='bloc7_1_0_0x2_0_0_0_1'  className={ "text-xs font-bold mx-2"}     >{`il y a 2 mois`} </div></div>
                    <div id='bloc7_1_0_0x2_0_0_1'  className={ "p-1 text-left grow overflow-hidden h-12"}     >{`Au secours !!!! ???????? nous n'avons pas eu d'eau chaude durant tout le séjour service clientèle inexistant A fuir !!! ????????`} </div>
                    <div id='bloc7_1_0_0x2_0_0_2'  className={ "underline text-xs font-bold p-1"}     >{`Afficher plus`} </div></div>
                  <div id='bloc7_1_0_0x2_0_1'  className={ "flex flex-row w-full"}     > <img  id='bloc7_1_0_0x2_0_1_0' src="https://fs.appisyou.com/apps/konjaque/431708938608803.jpg" className={ "h-10 w-10 rounded-full   object-cover"}     alt="undefined" />
                    <div id='bloc7_1_0_0x2_0_1_1'  className={ "flex flex-col items-start justify-center px-2"}     > <div id='bloc7_1_0_0x2_0_1_1_0'  className={ "font-bold text-sm"}     >{`Karl`} </div>
                      <div id='bloc7_1_0_0x2_0_1_1_1'  className={ "text-xs text-zinc-400"}     >{`Paris, France`} </div></div></div></div></div></div></div></div><div id='bloc8'  className={ "flex flex-col justify-start items-center p-4 w-full"}     > <div id='bloc8_0'       > <FontAwesomeIcon  id='bloc8_0_0'  icon={ fa.faDesktopAlt}      className={ "text-3xl m-1 text-secondary_color"}  />
            <FontAwesomeIcon  id='bloc8_0_1'  icon={ fa.faMobileAndroidAlt}      className={ "text-3xl m-1 text-secondary_color "}  /></div>
          <div id='bloc8_1'  className={ "font-bold"}     >{`Télécharger l'application KONJAQUE !`} </div>
          <div id='bloc8_2'  className={ "m-1 "}     >{`Gérer votre abonnement, vos horaires de livraison; participez à la communauté, informez-vous avec la newsletter. `} </div></div><div id='bloc9'  className={ "w-full flex flex-col justify-center items-center"}    onStart = { action_event_447_bloc9} > <div id='bloc9_0'  className={ "h-40  w-full bg-orange-400  bg-primary_color"}     > </div>
          <div id='bloc9_1'  className={ "   -mt-32 -mb-32    md:-my-20 z-10 flex flex-col items-center justify-center md:flex-row"}     > <div id='bloc9_1_0'  className={ "bg-white  w-40 h-40 z-10 rounded-xl p-2"}     > <img  id='bloc9_1_0_0' src="undefined" className={ "w-full h-full rounded-xl   object-cover"}     alt="undefined" /></div>
            <div id='bloc9_1_1'  className={ " py-2 bg-zinc-900 flex flex-row items-center rounded-lg border-white"}    onClick = { action_event_453_bloc9_1_1} > <FontAwesomeIcon  id='bloc9_1_1_0'  icon={ fa.faDownload}      className={ "w-8 h-8 text-white"}  />
              <div id='bloc9_1_1_1'  className={ "px-1 flex flex-col justify-center items-center  "}     > <div id='bloc9_1_1_1_0'  className={ "text-white text-xs"}     >{`Install with`} </div>
                <div id='bloc9_1_1_1_1'  className={ "text-white text-xl font-extrabold"}     >{`AppIsYou`} </div></div></div>
            <div id='bloc9_1_2'  className={ " py-2 bg-zinc-900 flex flex-row items-center rounded-lg border-white"}    onClick = { action_event_454_bloc9_1_2} > <FontAwesomeIcon  id='bloc9_1_2_0'  icon={ fa.faDownload}      className={ "w-8 h-8 text-white"}  />
              <div id='bloc9_1_2_1'  className={ "px-1 flex flex-col justify-center items-center  "}     > <div id='bloc9_1_2_1_0'  className={ "text-white text-xs"}     >{`Install with`} </div>
                <div id='bloc9_1_2_1_1'  className={ "text-white text-xl font-extrabold"}     >{`AppIsYou`} </div></div></div></div>
          <div id='bloc9_2'  className={ "h-40  w-full bg-blue-200 bg-secondary_color"}     > </div></div><div id='bloc10'  className={ "w-full bg-black bg-primary_color flex flex-row justify-center"}     > <div id='bloc10_0'  className={ "    p-2 h  grid  grids-cols-1  md:grid-cols-2  lg:grids-cols-3  xl:grid-cols-4 gap-1 text-white"}     > <div id='bloc10_0_0'  className={ " flex flex-col items-start p-4"}     > <div id='bloc10_0_0_0'  className={ "text-xl font-bold"}     >{`A propos`} </div>
              <div id='bloc10_0_0_1'       >{`Nos valeurs`} </div>
              <div id='bloc10_0_0_2'       >{`Nos missions`} </div>
              <div id='bloc10_0_0_3'       >{`Parrainnage`} </div></div>
            <div id='bloc10_0_1'  className={ " flex flex-col items-start p-4"}     > <div id='bloc10_0_1_0'  className={ "font-bold text-xl"}     >{`Contact`} </div>
              <div id='bloc10_0_1_1'       >{`Communication`} </div>
              <div id='bloc10_0_1_2'       >{`Gestion clientèle`} </div>
              <div id='bloc10_0_1_3'       >{`Boutique en ligne`} </div>
              <div id='bloc10_0_1_4'       > </div></div>
            <div id='bloc10_0_2'  className={ " flex flex-col items-start p-4"}     > <div id='bloc10_0_2_0'  className={ "font-bold text-xl"}     >{`Légal`} </div>
              <div id='bloc10_0_2_1'       >{`Conditions générales d'utilisation`} </div>
              <div id='bloc10_0_2_2'       >{`Conditions générales de vente`} </div>
              <div id='bloc10_0_2_3'       >{`Politique de confidentialité`} </div></div>
            <div id='bloc10_0_3'  className={ " flex flex-col items-start p-4"}     > <div id='bloc10_0_3_0'  className={ "text-3xl font-bold"}     > </div>
              <div id='bloc10_0_3_1'  className={ "text-base italic"}     >{`Le bio dans son panier !`} </div>
              <div id='bloc10_0_3_2'  className={ "underline font-bold"}     >{`@2024 Konjaque`} </div></div></div></div></>
  
}

export default Component8Page;
